@tailwind base;
@tailwind components;
@tailwind utilities;

@import "primeng/resources/primeng";
@import "primeicons/primeicons.css";

:root {
  --table-header: #e1c556;
}

.p-calendar-w-btn .p-inputtext {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.p-calendar-w-btn .p-datepicker-trigger {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.bg-content {
  background-color: var(--surface-a);
}

.bg-ground {
  background-color: var(--surface-b);
}

.bg-sidebar {
  background-color: #292929;
}

.text-color {
  color: var(--text-color);
}

.bg-card {
  background-color: var(--surface-card);
}

.custom-table {
  width: 100%;
}

p-columnfilter .p-column-filter-menu-button {
  display: none !important;
}

.custom-table table > thead > tr > th {
  border: none !important;
}

.custom-table table th:first-child {
  border-radius: 10px 0 0 10px;
}

.custom-table table th:last-child {
  border-radius: 0 10px 10px 0;
}

.custom-table table .p-datatable-thead > tr > th {
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;
}

.custom-table table th {
  background-color: var(--table-header) !important;
  color: var(--primary-color-text) !important;
}

.surface-border {
  background-color: var(--surface-border);
}

.p-dialog.header-danger .p-dialog-header {
  background-color: #f8d7da;
  color: #842029;
}

.p-dialog.header-info .p-dialog-header {
  background-color: #cfe2ff;
  color: #084298;
}

.p-toast .p-toast-message .p-toast-message-content {
  padding: 1rem;
  border-width: 0 0 0 0 !important;
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 0.2rem 1rem !important;
}

.p-datatable-wrapper {
  min-height: 400px;
}

.p-datepicker table td{
  padding: 1px 1px !important;
}
