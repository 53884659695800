:root {
  --table-header: #e1c556;
}

.p-calendar-w-btn .p-inputtext {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.p-calendar-w-btn .p-datepicker-trigger {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.bg-content {
  background-color: var(--surface-a);
}

.bg-ground {
  background-color: var(--surface-b);
}

.bg-sidebar {
  background-color: #292929;
}

.text-color {
  color: var(--text-color);
}

.bg-card {
  background-color: var(--surface-card);
}

.custom-table {
  width: 100%;
}

p-columnfilter .p-column-filter-menu-button {
  display: none;
}

.custom-table table > thead > tr > th {
  border: none;
}

.custom-table table th:first-child {
  border-radius: 10px 0 0 10px;
}

.custom-table table th:last-child {
  border-radius: 0 10px 10px 0;
}

.custom-table table .p-datatable-thead > tr > th {
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;
}

.custom-table table th {
  background-color: var(--table-header);
  color: var(--primary-color-text);
}

.main-tab-menu-border {
  border-color: #e7e7e7;
  border-bottom-width: 1px;
}

// .main-tab-menu {
//   border-color: #e7e7e7;
//   border-bottom: 2px;
// }
.main-tab-menu .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
  background: transparent;
  border: none;
}

.main-tab-menu .p-tabmenuitem:not(.p-highlight) > .p-menuitem-link {
  border-top: none;
  border-right: none;
  border-bottom: 1px solid #e7e7e7;
  border-left: none;
}

.main-tab-menu .p-tabmenuitem.p-highlight > .p-menuitem-link {
  border-top: none !important;
  border-right: none !important;
  border-bottom: 1px solid var(--primary-color) !important;
  border-left: none !important;
  color: var(--primary-color) !important;
  background: transparent !important;
  font-weight: 700 !important;
}

.main-tab-menu .p-tabmenuitem:hover > .p-menuitem-link {
  border-top: none !important;
  border-right: none !important;
  border-bottom: 1px solid var(--primary-color) !important;
  border-left: none !important;
  color: var(--primary-color) !important;
  background: transparent !important;
}



.main-tab-view .p-tabview .p-tabview-nav {
  background: transparent;
  border: 1px solid #e7e7e7;
  border-width: 0 0 1px 0;
}

.main-tab-view ul.p-tabview-nav > li > .p-tabview-nav-link {
  border-top: none;
  border-right: none;
  border-bottom: 1px solid #e7e7e7;
  border-left: none;
}

.main-tab-view ul.p-tabview-nav > li.p-highlight > .p-tabview-nav-link {
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #0094F6;
  color: #0094F6;
  font-weight: 700;
}

.surface-border {
  background-color: var(--surface-border);
}

.p-dialog.header-danger .p-dialog-header {
  background-color: #f8d7da;
  color: #842029;
}

.p-dialog.header-info .p-dialog-header {
  background-color: #cfe2ff;
  color: #084298;
}

.p-toast .p-toast-message .p-toast-message-content {
  padding: 1rem;
  border-width: 0 0 0 0;
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 0.2rem 1rem;
}

.p-datatable-wrapper {
  min-height: 400px;
}

.card {
  border-radius: 8px;
  background-color: #fff;
  padding: 20px 20px;
}

.breadcrumb {
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  align-items: end;
}

.breadcrumb .title {
  display: flex;
  color: var(--primary-color);
  font-size: 1.25rem;
  font-weight: 700;
  align-items: end;
}

// .card-header .title::after {
//   display: flex;
//   font-family: primeicons;
//   content: "\e901";
//   font-size: 0.75rem;
//   color: #292929;
//   margin-left: 0.5rem;
//   margin-right: 0.5rem;
//   margin-bottom: 0.5rem;
//   align-items: end;
// }

.card .card-header .title {
  display: flex;
  font-size: 1.25rem;
  font-weight: 700;
  color: #292929;
  align-items: end;
  margin-bottom: 1rem;
}
